import React from "react";
import PageLayout from "../components/PageLayout";
import { graphql } from "gatsby";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { goTo } from "../utilities/utils";

export default function activation() {
  return (
    <PageLayout>
      <div className="container">
        <div className="row" style={{ alignContent: "center" }}>
          <div className="col-md-6">
            <div className="error-info-box">
              <div className="error-404-box">
                <h1>
                  <Trans>
                    Error
                  </Trans>
                </h1>
              </div>
              <div className="error-description">
                <Trans>
                  Oops! We can't seem to find the page you are looking for.
                </Trans>
              </div>
              <div className="error-navigation">
                <div className="error-navigation-description">
                  <Trans>Here are some helpful links instead:</Trans>
                </div>
                <div className="error-navigation-links">
                  <ul>
                    <li onClick={() => goTo("/")}>
                      <Trans>Home</Trans>
                    </li>
                    <li onClick={() => goTo("/plans")}>
                      <Trans>Plans</Trans>
                    </li>
                    <li onClick={() => goTo("/support")}>
                      <Trans>Support</Trans>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ImgBox className="error-image-box">
              <img className="error-image-box-img" src="/images/404/404_error.png" alt="" />
            </ImgBox>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

const ImgBox = styled.div`
  @media (max-width: 1440px) {
    margin-left:-100px;
  }

  @media (max-width: 732px) {
    margin-left: 0px;
  }  
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
